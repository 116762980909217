jQuery(document).ready(function(){
    jQuery('.jra-logo-slider').slick({
      autoplay: true,
      autoplaySpeed: 0,
      speed: 5000,
      cssEase: 'linear',
      slidesToScroll: 1,
      infinite: true,
      swipeToSlide: false,
      variableWidth: true,
      swipe: false,
      draggable: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnClick: false,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            variableWidth: false
          }
        }
      ]
    });
  });

  jQuery(document).ready(function(){
    jQuery('.jra-testimonial-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      speed: 300,
      infinite: true,
      autoplaySpeed: 5000,
      autoplay: true,
      responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
    });
  });