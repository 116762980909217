jQuery(document).ready(function() {
    // Add class to body when scrolling to add white background
    var targetDiv = jQuery('body');
  
    jQuery(window).scroll(function() {
         var windowpos = jQuery(window).scrollTop();
         var companyDesktopLogoImage = jQuery('#jra-company-logo-desktop');
         var companyMobileLogoImage = jQuery('#jra-company-logo-mobile');
         var mobileNavbarBurger = jQuery('#mobile-navbar-burger');
  
         // change amount here to choose distance from top to add class
         var body = document.querySelector('body');
         if (body.classList.contains('page-template-template-transparent-heading-dark')) {
               if( windowpos >= 50 ) {
                    targetDiv.addClass('scrolling-active');
                    companyDesktopLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_Black_Desktop_Logo.svg');
                    companyMobileLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_Black_Mobile_Logo.svg');
                    mobileNavbarBurger.css('background-image', 'url(/wp-content/themes/jra/images/JRA_Black_Menu_Icon.svg)');
               } else {
                    targetDiv.removeClass('scrolling-active');
                    companyDesktopLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_White_Desktop_Logo.svg');
                    companyMobileLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_White_Mobile_Logo.svg');
                    mobileNavbarBurger.css('background-image', 'url(/wp-content/themes/jra/images/JRA_White_Menu_Icon.svg)');
               }
         } else if (body.classList.contains('single-case-study')) {
               if( windowpos >= 50 ) {
                    targetDiv.addClass('scrolling-active');
                    companyDesktopLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_Black_Desktop_Logo.svg');
                    companyMobileLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_Black_Mobile_Logo.svg');
                    mobileNavbarBurger.css('background-image', 'url(/wp-content/themes/jra/images/JRA_Black_Menu_Icon.svg)');
               } else {
                    targetDiv.removeClass('scrolling-active');
                    companyDesktopLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_White_Desktop_Logo.svg');
                    companyMobileLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_White_Mobile_Logo.svg');
                    mobileNavbarBurger.css('background-image', 'url(/wp-content/themes/jra/images/JRA_White_Menu_Icon.svg)');
               }
         } else {
               if( windowpos >= 50 ) {
                    targetDiv.addClass('scrolling-active');
                    companyDesktopLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_Black_Desktop_Logo.svg');
                    companyMobileLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_Black_Mobile_Logo.svg');
                    mobileNavbarBurger.css('background-image', 'url(/wp-content/themes/jra/images/JRA_Black_Menu_Icon.svg)');
               } else {
                    targetDiv.removeClass('scrolling-active');
                    companyDesktopLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_Black_Desktop_Logo.svg');
                    companyMobileLogoImage.attr('src', '/wp-content/themes/jra/images/JRA_Black_Mobile_Logo.svg');
                    mobileNavbarBurger.css('background-image', 'url(/wp-content/themes/jra/images/JRA_Black_Menu_Icon.svg)');
               }
         }
    });
  });